import React from 'react';
import { Hr } from '@GDM/Hr';
import MandateSelect from '../../fields/MandateSelect/MandateSelect';
import { ModelSelect } from '../../fields/ModelSelect';
import { SerialNumberInput } from '../../fields/SerialNumberInput';
import { DataProvider } from './DataProvider';
import styles from './default.module.scss';
import { DirectConnection } from './DirectConnection';
import { MeterRequest } from './MeterRequest';

export const DefaultItPtEs = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className="p-3">
          <MandateSelect />
        </div>
        <Hr />

        <DataProvider />
        <DirectConnection>
          <div className="d-flex gap-3 mt-3 hide-empty">
            <ModelSelect />
            <SerialNumberInput />
          </div>
        </DirectConnection>
      </div>
      <MeterRequest />
    </div>
  );
};
