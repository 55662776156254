import React, { useEffect, useState } from 'react';
import { Alert } from '@GDM/Alert';
import { Button } from '@GDM/Button';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import { useGrids } from '@hooks/requests/useGrids';
import useAxiosDeprecated from '@hooks/useAxios';
import { useFormLegacy } from '@hooks/useFormLegacy';
import useTranslation from '@hooks/useTranslation';
import { COUNTRIES } from '@utils/constants/countries';
import { upload_v2_mandates_path } from '@utils/routes';
import { CountryCode } from '@utils/types/countries';
import { MandateFormType, Mandate } from '@utils/types/mandate';
import GeneratePDFButton from './buttons/GeneratePDFButton';
import SaveMandateBtn from './buttons/SaveMandateBtn';
import { CompanyNumberInput } from './fields/CompanyNumberInput';
import { CountrySelect } from './fields/CountrySelect';
import { GridSelect } from './fields/GridSelect';
import { MandateSelect } from './fields/MandateSelect';
import { SignatoryEmailInput } from './fields/SignatoryEmailInput';
import { SignatoryNameInput } from './fields/SignatoryNameInput';
import { SignInput } from './fields/SignInput';
import { UploadMandateInput } from './fields/UploadMandateInput';
import RenderStatus from './text/RenderStatus';
import RequestStatusMessage from './text/RequestStatusMessage';
import { formatUploadBody, formatUpsertMandateBody } from './utils/formatBody';

export type FormMandatesProps = {
  allowSelect: boolean;
  selectedMandate: MandateFormType | null;
  onSuccess: (mandate?: Mandate) => void;
  onClose?: () => void;
  defaultValues?: Partial<MandateFormType>;
  forceDisable?: Partial<Record<keyof MandateFormType, boolean>>;
};

/**
 * Component to edit or create a mandate
 */
export const FormMandates = ({
  selectedMandate,
  allowSelect,
  onSuccess,
  onClose,
  defaultValues,
  forceDisable,
}: FormMandatesProps) => {
  const _defaultValues: MandateFormType = {
    uuid: 'select_mandate',
    country: null,
    grid_name: null,
    signatory_email: '',
    signatory_name: '',
    mandate_file: null,
    sirets: '',
    status: null,
    mandataire: '',
    sign_type: 'online',
    ...(defaultValues || {}),
  };

  const [form, setForm, setFormData] = useFormLegacy(_defaultValues);

  const [requestMandate, upsertMandate] = useAxiosDeprecated({ url: '/mandates', method: 'POST' });
  const [requestUpload, upload] = useAxiosDeprecated({ url: upload_v2_mandates_path(), method: 'POST' });
  const grids = useGrids(form.country);

  const [allowEdit, setAllowEdit] = useState(true);
  const [valuesHasChanged, setValuesHasChanged] = useState(false);
  const disabled = !allowEdit;
  const toRenew = form.status === 'to_renew' || form.status === 'expired' || form.status === 'signed';
  const requiredFields =
    !form.sirets || !form.signatory_name || !form.signatory_email || requestMandate.loading === 'LOADING';

  const { t } = useTranslation();

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (!allowSelect || form.uuid === 'new_mandate') setAllowEdit(true);
    else setAllowEdit(false);
  }, [form.uuid]);

  useEffect(() => {
    if (selectedMandate) setForm((previous) => ({ ...previous, ...selectedMandate }));
  }, [selectedMandate]);

  useEffect(() => {
    if (requestMandate.loading === 'LOADED' && requestMandate.data) {
      setForm({
        ...form,
        uuid: requestMandate.data.mandate.uuid,
        status: requestMandate.data.mandate.status,
        mandataire: requestMandate.data.mandate.mandataire,
      });
      if (onSuccess) onSuccess(requestMandate.data.mandate);
    }

    if (requestMandate.loading === 'LOADED' && form.mandate_file && !requestUpload.loading)
      upload({
        data: formatUploadBody({
          uuid: requestMandate.data.mandate.uuid,
          mandate_file: form.mandate_file || null,
        }),
      });

    if (requestMandate.loading === 'ERROR') setAllowEdit(true);
  }, [requestMandate]);

  useEffect(() => {
    if (requestUpload.loading === 'LOADED' && requestUpload.data) {
      setForm({ ...form, uuid: requestUpload.data.mandate.uuid, status: requestUpload.data.mandate.status });
      if (onSuccess) onSuccess(requestUpload.data.mandate);
    }
  }, [requestUpload]);

  /**
   * ACTIONS
   */
  const handleChange =
    (key: keyof MandateFormType) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData(key, event.target.value);
      setValuesHasChanged(true);
    };

  /**
   * Handle the automatic selection of the mandate
   * @param {InputEvent} event Input event
   */
  const handleMandateChange = (mandateForm: MandateFormType) => {
    setForm({ ...mandateForm, sign_type: form.sign_type });
    setValuesHasChanged(true);
  };

  const handleSave = () => {
    const data = formatUpsertMandateBody(form);
    upsertMandate({ data });
    setAllowEdit(false);
  };

  return (
    <div>
      {form.status ? (
        <Row>
          <Col md={12} className="tt:uppercase">
            <RenderStatus status={form.status} />
          </Col>
        </Row>
      ) : null}

      {allowSelect ? <MandateSelect value={form.uuid} onChange={handleMandateChange} /> : null}

      <div className={form.uuid !== 'select_mandate' ? 'd-block' : 'd-none'}>
        <Row>
          <Col md={6} className="my-1">
            <CountrySelect
              countries={Object.keys(COUNTRIES) as CountryCode[]}
              value={form.country}
              onChange={(country: CountryCode | null) => {
                setFormData('country', country);
                setValuesHasChanged(true);
              }}
              disabled={forceDisable?.country || disabled || false}
            />
          </Col>
          <Col md={6} className="my-1">
            <GridSelect
              gridNames={(grids.data || []).map((grid) => grid.name)}
              value={form.grid_name}
              onChange={(grid_name: string | null) => {
                setFormData('grid_name', grid_name);
                setValuesHasChanged(true);
              }}
              isLoading={grids.isPending}
              disabled={forceDisable?.grid_name || disabled || false}
            />
          </Col>
          <Col md={6} className="my-1">
            <SignatoryNameInput
              value={form.signatory_name}
              onChange={handleChange('signatory_name')}
              disabled={disabled}
            />
          </Col>
          <Col md={6} className="my-1">
            <SignatoryEmailInput
              value={form.signatory_email}
              onChange={handleChange('signatory_email')}
              disabled={disabled}
            />
          </Col>
          <Col md={12} className="my-1">
            <CompanyNumberInput
              value={form.sirets}
              country={form.country}
              onChange={handleChange('sirets')}
              disabled={forceDisable?.sirets || disabled || toRenew || false}
            />
          </Col>
          <Col md={12} className="my-1">
            <SignInput
              value={form.sign_type || ''}
              onChange={(value) => setFormData('sign_type', value ?? null)}
              disabled={forceDisable?.sign_type || false}
            />
          </Col>
          {form.sign_type === 'offline' ? (
            <Col md={12} className="my-1">
              <UploadMandateInput
                form={form}
                setFormData={setFormData}
                setValuesHasChanged={setValuesHasChanged}
                disabled={disabled}
              />
            </Col>
          ) : null}
        </Row>

        {allowEdit && form.uuid !== 'new_mandate' && (
          <Alert
            size="sm"
            variant="danger"
            label="admin.installations.siren_edit_warning"
            className="mb-4 text-small"
            full
          />
        )}

        <Row>
          <Col className="d-flex flex-row mb-3 justify-content-end align-items-center" md={12}>
            {(!form.sirets || !form.signatory_name || !form.signatory_email) && (
              <div style={{ flex: 1 }}>
                <Text size="sm" text="admin.installations.all_fields_required" type="danger" />
              </div>
            )}

            {requestMandate.error?.message && (
              <div style={{ flex: 1, overflow: 'hidden' }} className="mr-2">
                <Text size="sm" text={requestMandate.error?.message} type="danger" />
              </div>
            )}

            <div className="d-flex">
              {form.sign_type === 'offline' ? <GeneratePDFButton form={form} /> : null}
              {onClose && <Button variant="link" size="sm" text="common.cancel" onClick={onClose} className="mr-2" />}
              <SaveMandateBtn
                onSave={handleSave}
                setAllowEdit={setAllowEdit}
                allowEdit={allowEdit}
                loading={requestMandate.loading}
                disabled={disabled}
                toRenew={toRenew}
                requiredFields={requiredFields}
                valuesHasChanged={valuesHasChanged}
              />
            </div>
          </Col>

          <Col md={12}>
            {requestMandate.error?.type === 'IN-01' && (
              <p className="text-red">{t('admin.installations.errors.mandate_not_found')}</p>
            )}
            {requestMandate.error?.type === 'IN-02' && (
              <p className="text-red">{t('admin.installations.errors.mandate_expired')}</p>
            )}
            <RequestStatusMessage status={requestMandate.loading}>
              {t('admin.installations.correctly_saved')}
            </RequestStatusMessage>
            {form.sign_type === 'online' ? (
              <RequestStatusMessage status={requestMandate.loading}>
                {t('admin.installations.will_receive_mail')}
              </RequestStatusMessage>
            ) : null}
          </Col>

          <Col md={12}>
            <RequestStatusMessage status={requestUpload.loading}>
              {t('admin.installations.correctly_inserted')}
            </RequestStatusMessage>
          </Col>
        </Row>
      </div>
    </div>
  );
};
