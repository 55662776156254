import { MandateFormType } from '@utils/types/mandate';
import { MandateBody } from '../types';

export const formatUpsertMandateBody = (form: MandateFormType) => {
  const sirets = form.sirets
    .split(',')
    .flatMap((s) => s.split('\n'))
    .filter((s) => s !== '')
    .map((s) => s.replace(/^\s+|\s+$/g, ''));
  const data: MandateBody = {
    mandate: {
      country: form.country,
      grid_name: form.grid_name,
      mandataire: form.signatory_name,
      signatory_name: form.signatory_name,
      signatory_email: form.signatory_email,
      sirets,
    },
  };

  if (form.uuid && !['select_mandate', 'new_mandate'].includes(form.uuid)) {
    data.mandate.uuid = form.uuid;
  }
  if (form.sign_type === 'online') data.online_sign = true;

  return data;
};

export const formatUploadBody = (form: Partial<MandateFormType>) => {
  const formData = new FormData();
  if (form.uuid && !['select_mandate', 'new_mandate'].includes(form.uuid)) {
    if (form.mandate_file) formData.append('file', form.mandate_file);
    formData.append('mandate_uuid', form.uuid);
  }

  return formData;
};
