import React, { useEffect, useState } from 'react';
import { Textarea } from '@GDM/forms/Textarea';
import useTranslation from '@hooks/useTranslation';
import { CountryCode } from '@utils/types/countries';
import MarketPlayerConfig from '../../../../../../config/data/market_players.yml';

const config = MarketPlayerConfig['shared'];

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  disabled: boolean;
  country: CountryCode | null;
};

export const CompanyNumberInput = ({ onChange, value, disabled, country }: Props) => {
  const { t } = useTranslation();
  const [rows, setRow] = useState(1);
  const _value = value.split(',').join('\n');

  const label = (country && config[country]?.company_number_name) || 'owner.company_number';

  useEffect(() => {
    const numberLines = value.split(',').length;

    if (numberLines > 1) {
      setRow(Math.min(5, numberLines));
    }
  }, [value]);

  return (
    <>
      <Textarea
        full
        label={label}
        id="mandate-sirets"
        value={_value}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
        data-cy="sirets-input"
      />

      <em className="font-italic mb-3 text-small">{t('common.separate_comma')}</em>
    </>
  );
};
