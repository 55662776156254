import React from 'react';
import { CountrySelect as CountrySelectComponent } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { CountryCode } from '@utils/types/countries';

export const CountrySelect = ({
  countries,
  onChange,
  value,
  disabled,
}: {
  countries: CountryCode[];
  onChange: (country: CountryCode | null) => void;
  value: CountryCode | null;
  disabled: boolean;
}) => {
  const options = countries
    .map((country) => ({ label: `countries.${country}`, value: country }))
    .sort(sortOptionsByLabelAsc);

  return (
    <CountrySelectComponent
      options={options}
      onChange={(option) => onChange?.(option?.value ? (option.value as CountryCode) : null)}
      selectedOption={value}
      label="common.country"
      isDisabled={disabled}
      isClearable
    />
  );
};
