import React from 'react';
import { Select } from '@GDM/forms/Select/Select';
import { sortOptionsByLabelAsc } from '@utils/sorters';

export const GridSelect = ({
  gridNames,
  onChange,
  value,
  isLoading,
  disabled,
}: {
  gridNames: string[];
  onChange: (country: string | null) => void;
  value: string | null;
  isLoading: boolean;
  disabled: boolean;
}) => {
  const options = gridNames.map((gridName) => ({ label: gridName, value: gridName })).sort(sortOptionsByLabelAsc);

  return (
    <Select
      options={options}
      selectedOption={options.find((option) => option.value === value)?.value}
      onChange={(option) => (option ? onChange(option.value) : onChange(null))}
      isLoading={isLoading}
      isDisabled={disabled}
      label="common.grid"
    />
  );
};
