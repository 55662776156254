import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { useMandatesContext } from '@context/Mandates.context';
import { Button } from '@GDM/Button';
import { Select } from '@GDM/forms';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { useSelectedGrid } from '@pages/Installations/Installation/context/useSelectedGrid.context';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { GroupBase, OptionProps } from 'react-select';
import { FormMandates } from '../../../../../../../Installations/form/FormMandates';
import { MandateOption } from './mandate-select.types';
import { MandateSelectBadge, MandateSelectOption } from './MandateSelectOption';

const MandateSelect = () => {
  const form = useInstallationForm();
  const country = form.watch('country');
  const { data, loading, execute, loaded } = useMandatesContext();
  const { rules, disabled } = useInstallationField('mandate_uuid');
  const [lastFetchedDatetime, setLastFetchedDatetime] = useState<number>(Date.now());
  const {
    query: { data: installation },
  } = useInstallationFormQuery();

  const installationCompanyNumber = installation?.owner_company_number;

  const { selectedGrid } = useSelectedGrid();
  const gridName = selectedGrid?.name;

  const [mandateModalVisible, setMandateModalVisible] = useState(false);
  const toggle = () => setMandateModalVisible((s) => !s);

  // If one of following cases: grid = Enedis or country = France and grid = RTE, Electricite de Strasbourg or Synelva.
  // Then it shows the current mandate form with the current workflow.
  const signMandateOffline =
    country !== 'FR' || !['Électricité de Strasbourg (ÉS)', 'RTE', 'Synelva'].includes(gridName || '');

  const options: MandateOption[] = useMemo(() => {
    if (!data) return [];

    return data
      ?.filter((mandate) =>
        mandate.sirens_with_company_names.some((company) => company.company_number === installationCompanyNumber),
      )
      ?.map((mandate) => ({ label: mandate.representative, value: mandate.uuid, status: mandate.status }))
      .sort(sortOptionsByLabelAsc)
      .sort((a, b) => (a.status === 'signed' ? -1 : 1) - (b.status === 'signed' ? -1 : 1));
  }, [data, installationCompanyNumber]);

  useEffect(() => {
    const refreshMandates = () => {
      if (loading) return;

      // Refresh mandates if the last fetch was more than 1 minute ago on window focus
      if (Date.now() - lastFetchedDatetime < 1000 * 60) return;

      execute?.();
    };

    window.addEventListener('focus', refreshMandates);

    return () => {
      window.removeEventListener('focus', refreshMandates);
    };
  }, [execute, loading, lastFetchedDatetime]);

  useEffect(() => {
    setLastFetchedDatetime(Date.now());
  }, [loaded]);

  return (
    <>
      <Modal
        isOpen={mandateModalVisible}
        toggle={toggle}
        size="lg"
        header="admin.installations.add_mandate"
        hideCancelButton
      >
        <FormMandates
          selectedMandate={null}
          allowSelect={false}
          onSuccess={() => {
            execute?.(); // refresh mandates list
            toggle();
          }}
          forceDisable={{ country: true, grid_name: true, sign_type: true }}
          defaultValues={{
            uuid: 'new_mandate',
            country,
            grid_name: gridName,
            sign_type: signMandateOffline ? 'offline' : 'online',
            sirets: installationCompanyNumber,
          }}
        />
      </Modal>

      <Controller
        control={form.control}
        name="mandate_uuid"
        rules={rules}
        render={({ field, fieldState }) => (
          <Select
            options={options}
            classNamePrefix="select-mandate"
            onChange={(option) => field.onChange(option?.value)}
            components={{
              Option: MandateSelectOption as unknown as ComponentType<
                OptionProps<Option<string>, boolean, GroupBase<Option<string>>>
              >,
            }}
            selectedOption={field.value}
            label="admin.installations.select_mandate"
            size="lg"
            isClearable
            isLoading={loading}
            isOptionDisabled={(option) => !signMandateOffline && (option as MandateOption).status !== 'signed'}
            isDisabled={disabled || !installationCompanyNumber}
            formatOptionLabel={(data) => (
              <MandateSelectBadge status={(data as MandateOption).status} label={(data as MandateOption).label} />
            )}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      {(options.length === 0 || !installationCompanyNumber) && (
        <Text text="admin.installations.select_mandate_help_1" size="sm" multiline className="mt-1" />
      )}

      <Button
        size="xs"
        text="admin.installations.manage_and_add_mandate"
        variant="link"
        icon="Plus"
        onClick={toggle}
        className="mt-1"
      />
    </>
  );
};

export default MandateSelect;
