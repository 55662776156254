import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { MandateFormType } from '@utils/types/mandate';
import { useInstallationsPageContext } from '../../context/installations-page.context';

interface MandateOption extends Option {
  expired?: boolean;
}

export const SELECT_MANDATE_VALUE = 'select_mandate';
export const NEW_MANDATE_VALUE = 'new_mandate';

export const MandateSelect = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (formData: MandateFormType) => void;
}) => {
  const { mandates } = useInstallationsPageContext();
  const handleMandateChange = (uuid?: string) => {
    const mandate = mandates.find((i) => i.uuid === uuid);
    if (mandate) {
      const newValues: MandateFormType = {
        uuid: mandate.uuid,
        country: mandate.country,
        grid_name: mandate.grid_name,
        signatory_name: mandate.signatory_name || '',
        signatory_email: mandate.signatory_email || '',
        sirets: mandate.sirets?.join(','),
        mandate_file: null,
        status: mandate.status,
        mandataire: mandate.representative,
        sign_type: 'online',
      };

      onChange(newValues);
    } else {
      onChange({
        country: null,
        grid_name: null,
        uuid: uuid ?? '',
        signatory_name: '',
        signatory_email: '',
        sirets: '',
        mandate_file: null,
        status: null,
        mandataire: '',
        sign_type: 'online',
      });
    }
  };

  const options: MandateOption[] = [
    { value: SELECT_MANDATE_VALUE, label: 'common.select_default_placeholder' },
    { value: NEW_MANDATE_VALUE, label: 'admin.installations.new_mandate' },
    { value: '-', label: '───────────', disabled: true },
    ...mandates.map((mandat) => ({
      value: mandat.uuid,
      label: mandat.representative,
      expired: mandat.status === 'expired',
    })),
  ];

  const isOptionDisabled = (option: MandateOption) => (option.expired ?? option.disabled) || false;

  return (
    <div className="mb-3">
      <Select
        label="admin.installations.select_mandate"
        options={options}
        selectedOption={value}
        isOptionDisabled={isOptionDisabled}
        onChange={(opt) => handleMandateChange(opt?.value)}
        classNamePrefix="select-mandate"
      />
    </div>
  );
};
